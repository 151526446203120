import { graphql, navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import { OfficeCard } from "../components/Card/types"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import { SectionTitle } from "../components/SectionTitle"
import { Subtitle } from "../components/SectionTitle/subtitle"
import Spacer from "../components/Spacer"
import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"

const ContactPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const offices = transformToArray(props.data, "locations")
  const content = createStaticContent(props.data, "contact")

  return (
    <PageLayout
      locations={locations}
      static_content={content}
      locationLink={false}
    >
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <SectionTitle
        title={formatStaticContent(content, "contact-title")}
        desc={formatStaticContent(content, "contact-desc")}
        bg_icon={"lineart4"}
      />
      <Spacer height={60} />
      <PageContentsWrapper>
        <Subtitle
          title={formatStaticContent(content, "offices-title")}
          desc={formatStaticContent(content, "offices-desc")}
        />
        <ItemRows child_limit={0}>
          {_.map(offices, (office, i) => (
            <OfficeCard {...office} key={i} />
          ))}
        </ItemRows>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_contact: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "contact-us" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

export default ContactPage
